import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFound from '../images/not-found-image.svg';
import "../styles/global.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="w3-row container not-found">
      <div className="w3-col m12 l12 element">
        <img src={NotFound} alt="" />
        <h3>您查看的頁面目前不可用<br />這可能是因為鏈結已經改變</h3>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
